import domReady from '@wordpress/dom-ready'

function accordionInit () {
	// const accordion = document.querySelector('.standorte-content')
	const accItem = document.querySelectorAll('.standorte-sidebar-item')
	const accTitle = document.querySelectorAll('.standorte-sidebar-item-title')
	const pins = [...document.querySelectorAll('.standorte-map-pin')]
	for (let i = 0; i < accTitle.length; i++) {
		accTitle[i].addEventListener('click', toggleItem, false)
		accTitle[i].addEventListener('click', togglePin, false)
	}

	function toggleItem (e) {
		// deactivateAll()
		const itemClass = this.parentNode.className
		for (let i = 0; i < accItem.length; i++) {
			accItem[i].className = 'standorte-sidebar-item close'
		}
		if (itemClass === 'standorte-sidebar-item close') {
			this.parentNode.className = 'standorte-sidebar-item open'
		}
	}

	function togglePin (e) {
		const activePin = pins.filter(pin => pin.dataset.id === this.parentNode.dataset.id)[0]
		if (activePin.classList.contains('active')) {
			activePin.classList.remove('active')
			return
		}
		for (let i = 0; i < pins.length; i++) {
			pins[i].className = 'standorte-map-pin'
		}

		activePin.classList.add('active')
	}

}

function mapInit () {
	const pinHolder = document.querySelector('.standorte-map-pins')
	const mapImg = document.querySelector('.standorte-map-img')
	const mapImgSize = mapImg.getBoundingClientRect()
	const pins = document.querySelectorAll('.standorte-map-pin')
	const accItem = document.querySelectorAll('.standorte-sidebar-item')
	for (let pin of pins) {
		// const pinSize = pin.getBoundingClientRect()
		// const pinPosition = {
		//   x: pin.dataset.x, // ceva 35% din 701 //701 - 35% = 455
		//   y: pin.dataset.y,
		// }

		// const pinPosition = {
		//   x: (pin.dataset.x / 100) * mapImgSize.width, // ceva 35% din 701 //701 - 35% = 455
		//   y: (pin.dataset.y / 100) * mapImgSize.height,
		// }
		// pin.style.left = `${pinPosition.x}px`
		// pin.style.left = `calc(${pin.dataset.x}% - 38px)`
		// pin.style.top = `${pinPosition.y}px`
		// pin.style.top = `calc(${pin.dataset.y}% + 57px)`
		const pinPosition = {
			x: (pin.dataset.x / 100) * mapImgSize.width, // ceva 35% din 701 //701 - 35% = 455
			y: (pin.dataset.y / 100) * mapImgSize.width,
		}

		pin.style.transform = `translate(${pinPosition.x}px, ${pinPosition.y - 10}px)`
		pin.addEventListener('click', toggleItem, false)
	}

	function deactivateAll () {
		pins.forEach(function (pin, index) {
			pin.classList.remove('active')
		})
		accItem.forEach(function (item, index) {
			item.classList.remove('open')
			item.classList.add('close')
		})
	}

	function toggleItem (e) {
		const pinId = this.dataset.id
		const activeItem = document.querySelector(`.standorte-sidebar-item[data-id="${pinId}"]`)
		if (activeItem.classList.contains('open')) {
			activeItem.classList.remove('open')
			this.classList.remove('active')
			return
		}
		deactivateAll()
		activeItem.classList.toggle('open')
		this.classList.toggle('active')
		// accItem = document.querySelectorAll('.standorte-sidebar-item')
	}

	// console.log('mapImgSize', mapImgSize.width)
	window.onresize = () => {
		const mapImgRect = mapImg.getBoundingClientRect()
		for (let pin of pins) {
			// const pinSize = pin.getBoundingClientRect()
			// const pinPosition = {
			//   x: pin.dataset.x, // ceva 35% din 701 //701 - 35% = 455
			//   y: pin.dataset.y,
			// }
			// console.log((pin.dataset.x / 100) * initialPinHolderSize.width)
			const pinPosition = {
				x: (pin.dataset.x / 100) * mapImgRect.width, // ceva 35% din 701 //701 - 35% = 455
				y: (pin.dataset.y / 100) * mapImgRect.width,
			}

			pin.style.transform = `translate(${pinPosition.x}px, ${pinPosition.y - 10}px)`
			// pin.style.left = `${pinPosition.x}px`
			// pin.style.top = `${pinPosition.y}px`
			// pin.style.left = `${pin.dataset.x}%`
			// pin.style.top = `${pin.dataset.y}%`
		}

	}
}

domReady(() => {
	// const accItem = document.querySelectorAll('.standorte-sidebar-item')
	// accordionInit(accItem)
	// mapInit(accItem)
	accordionInit()
	mapInit()
})
